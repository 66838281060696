import React, { useState, useEffect } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useDebounce } from '../../utils/useDebounce';
import { useTranslation } from 'react-i18next';

const Lookup = ({
    label,
    entity,
    search,
    value,
    displayValue,
    selectValue,
    onChange,
    menuItemProps,
    entityService = { getList: async () => ({ data: [] }) },
}) => {
    const { t: translate } = useTranslation();

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const debouncedInputValue = useDebounce(inputValue, 300);

    const fetchAllOptions = async () => {
        setLoading(true);
        try {
            const fetchedOptions = await entityService.getList({ entity, search, input: '' });
            setOptions(Array.isArray(fetchedOptions.data) ? fetchedOptions.data : []);
        } catch (error) {
            setOptions([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchFilteredOptions = async (searchText) => {
        if (searchText.length < 3) return;

        setLoading(true);
        try {
            const fetchedOptions = await entityService.getList({ entity, search, input: searchText });
            setOptions(Array.isArray(fetchedOptions.data) ? fetchedOptions.data : []);
        } catch (error) {
            setOptions([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllOptions();
    }, [entity]);

    useEffect(() => {
        if (debouncedInputValue.length >= 3) {
            fetchFilteredOptions(debouncedInputValue);
        } else if (debouncedInputValue === '') {
            fetchAllOptions();
        }
    }, [debouncedInputValue]);

    return (
        <Autocomplete
            getOptionLabel={(option) => option[displayValue] ?? ''}
            noOptionsText={
                debouncedInputValue.length < 3
                  ? translate("lookUp.empty")
                  : translate("lookUp.wrongInput")
              }            
            options={options}
            loading={loading}
            value={value ? options.find((option) => option[selectValue] === value) : null}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(_, newValue) => {
                onChange(newValue ? newValue[selectValue] : null);
            }}
            onOpen={fetchAllOptions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                />
            )}
            renderOption={(props, option, { selected }) => (
                <MenuItem {...menuItemProps} selected={selected} {...props}>
                    {option[displayValue]}
                </MenuItem>
            )}
        />
    );
};

export default Lookup;
