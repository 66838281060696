import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { Grid2, Button, Popper } from '@mui/material';

import { ROUTES } from 'core/routing/routes';

import BookingSummary from './components/Summary';

import Layout from 'components/Layout';

import Select from 'core/libs/core-ui/components/Input/Select';
import QuantityPicker from 'core/libs/core-ui/components/Input/QuantityPicker';
import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import ExperienceAvailabilityPageComponent from 'core/libs/core-ui/components/ExperienceAvailabilityPage';
import AlertDialog from 'core/libs/core-ui/components/Dialog';

// import CheckoutItemSelect from 'core/libs/core-ui/components/CheckoutPage/components/CheckoutItemSelect';

import { formatDateToYYYYMMDD } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { ScheduleService } from 'core/api/services/schedule';

import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';
import { useExperiences } from 'core/contexts/experience';
import { useBookings } from 'core/contexts/booking';
import { useExperiencers } from 'core/contexts/experiencer';
import { ExperienceResourceService } from 'core/api/services/ExperienceInfo/expResource';
import { useStyles } from './styles';
import { ResourceService } from 'core/api/services/resource';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import CheckoutPageComponent from 'components/CheckoutPage';

const initialStateValues = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    start: '',
    end: '',
    mobilePhone: undefined,
    quantity: 1,
    date: '',
    price: undefined,
    status: undefined,
    currency: 'COP',
    repeat: false,
    mobilePhoneCountryCode: '',
    paidPrice: undefined,
    arrivalTime: undefined,
    isArrivalTimeInRange: false,
    arrivalTimeFormatted: new Date(),
    additional: '',
};

const BookingNew = () => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const { experiences, getExperiencesUpdated, setTimeOfArrival, timeOfArrival, contractExperiences, contractBundleExperiences, getContractExperiences, getContractBundleExperiences } = useExperiences();
    const { contract, contracts, getContracts, setContract } = useExperiencers();

    // const [onFirstCall, _] = useState(true);
    const [experiencerName, setExperiencerName] = useState([]); // This is used for dropdown display and selection
    const [allExpAndBundles, setAllExpAndBundles] = useState([]); // This is used for dropdown display and selection
    const [quantity, setQuantity] = useState(1);
    const [selectedExperienceItem, setSelectedExperienceItem] = useState(null);
    const [schedule, setSchedules] = useState();
    const [dateDatePicker, setDateDatePicker] = useState(new Date());
    const [selectedSlot, setSelectedSlot] = useState(null);
    // const [showErrorPrice, setShowErrorPrice] = useState(false);
    // const [checkoutId, setCheckoutId] = useState(0);
    const [categoriesData, setCategoriesData] = useState();
    const [rawDataCategories, setRawDataCategories] = useState();
    const [scheduleDate, setScheduleDate] = useState(() => formatDateToYYYYMMDD(new Date()));

    const { checkoutItems, setCheckoutItems, addCheckoutItem, addBooking, addContractBookingPayment, deleteContractBookingPayment } = useBookings();

    const [openAvailabilityModal, setOpenAvailabilityModal] = useState(false);
    // const handleSeletectedExperienceitem = (value) => {
    //     setSelectedExperienceItem(value);
    // };

    const handleClickAvailabilityModal = () => {
        setSelectedSlot(null);
        setResourceSelected([]);
        setOpenAvailabilityModal(true);
    };

    const onCloseAvailabilityModal = () => {
        setOpenAvailabilityModal(false);
    };

    const [bookingId, setBookingId] = useState(null);
    const [bookingCode, setBookingCode] = useState(null);
    const [successOpen, setSuccessOpen] = useState(false);
    // const [values, setValues] = useState(initialStateValues);
    const [resourceSelected, setResourceSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleRange, setVisibleRange] = useState({
        start: null,
        end: null,
    });

    // useEffect(() => {
    //     //TODO: revisar esta function, tiene error
    //     if (!checkoutItems.length) {
    //         return;
    //     }
    //     const auxCheckout = checkoutItems.map((it) => {
    //         if (it.checkoutId === selectedExperienceItem?.checkoutId) {
    //             it.date = timeOfArrival;
    //         }
    //         if (it.bundleId) {
    //             it.experiences?.forEach((itExp) => {
    //                 if (
    //                     itExp.checkoutId === selectedExperienceItem?.checkoutId
    //                 ) {
    //                     itExp.date = timeOfArrival;
    //                 }
    //             });
    //         }
    //         return { ...it };
    //     });
    //     setCheckoutItems(auxCheckout);
    // }, [timeOfArrival]);

    function isValidNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }
    const handleModalSuccess = () => {
        setSuccessOpen(true);
    };

    // useEffect(() => {

    //     if (!isValidNumber(selectedSlot?.price)) {
    //         return;
    //     }

    //     const auxSlot = selectedSlot;
    //     // setSelectedSlot(null);
    //     if (!checkoutItems.length) {
    //         return setCheckoutItems(auxSlot);
    //     }
    //     const auxCheckout = checkoutItems.map((it) => {
    //         if (it.id === selectedExperienceItem?.id) {
    //             it.slotPrice = auxSlot.price;
    //         }
    //         if (it.bundleId) {
    //             it.experiences?.forEach((itExp) => {
    //                 if (itExp.id === selectedExperienceItem?.id) {
    //                     itExp.slotPrice = auxSlot.price;
    //                 }
    //             });
    //         }
    //         return { ...it };
    //     });
    //     setCheckoutItems(auxCheckout);
    // }, [selectedSlot]);

    const { getUserInfoSync } = useAuth();

    useEffect(() => {
        // setLoading(true);
        // if (!selectedExperienceItem) {
        //     return;
        // }
        // ScheduleService.getByDateMonth(
        //     selectedExperienceItem.experienceId,
        //     selectedExperienceItem.experienceDetailId,
        //     scheduleDate,
        //     'monthly',
        //     quantity,
        //     contract && contract.id ? contract.id.toString(16) : 0,
        //     resourceSelected
        // ).then((res) => {
        //     setSchedules(res);
        //     setLoading(false);
        // });
        if (openAvailabilityModal && Date.parse(visibleRange.start) && Date.parse(visibleRange.end)) {
            setLoading(true);
            // if (!booking) {
            //     return;
            // }
            // if (Array.isArray(booking) && booking.length === 0) {
            //     return;
            // }
            // if (!booking.experienceDetailId) {
            //     return;
            // }
            // const exp = {
            //     id: booking.experienceId,
            //     experienceDetailId: booking.experienceDetailId,
            // };
            // setSelectedExperienceItem(exp);
            ScheduleService.getAvailability({
                expId: selectedExperienceItem.experienceId,
                fromDateTime: new Date(visibleRange.start),
                toDateTime: new Date(visibleRange.end),
                groupSize: quantity,
                code: contract && contract.id ? contract.id.toString(16) : 0,
                resources: resourceSelected,
            }).then((res) => {
                setSchedules([...res]);
                setLoading(false);
            });
        }
    }, [scheduleDate, selectedExperienceItem, quantity, resourceSelected, openAvailabilityModal, visibleRange]);

    const listExperiences = () => {
        // if (onFirstCall) {
        const auxCheckout = [];
        setCheckoutItems(auxCheckout);
        setTimeOfArrival(null);
        setSelectedExperienceItem(null);
        // }

        const allExp = [];
        let uniqueId = 0;
        if (experiences && experiences.length) {
            experiences.forEach((exp) => {
                allExp.push({
                    ...exp,
                    experiencer: experiencerName,
                    experienceId: exp.id,
                    id: uniqueId,
                    expDetailId: exp.experienceDetailId,
                });
                uniqueId++;
            });
        }
        setAllExpAndBundles(allExp);
    };
    useEffect(() => {
        listExperiences();
    }, [experiences]);

    const handleSelectContract = async (contract) => {
        // if (!contract || !contract.id) {
        //     const contracts = await getContracts();
        //     if (contracts && contracts.length) {
        //         await getContractExperiences(contracts[0].id);
        //         await getContractBundleExperiences(contracts[0].id);
        //     }
        // } else {
        // TODO: availability component should be in checkout page
        // selectedSlot, quantity, resourceSelected
        setSelectedSlot(null);
        setQuantity(1);
        setResourceSelected([]);
        if (contract && contract.id) {
            setAllExpAndBundles([]);
            setContract(contract);
            await getContractExperiences(contract.id);
            await getContractBundleExperiences(contract.id);
        } else {
            setContract({});
            listExperiences();
        }
    };
    useEffect(() => {
        // // if (onFirstCall) {
        // const auxCheckout = [];
        setCheckoutItems([]);
        setTimeOfArrival(null);
        setSelectedExperienceItem(null);
        // // }

        // const allExp = [];
        // let uniqueId = 0;
        // if (experiences && experiences.length) {
        //     experiences.forEach((exp) => {
        //         allExp.push({
        //             ...exp,
        //             experiencer: experiencerName,
        //             experienceId: exp.id,
        //             id: uniqueId,
        //             expDetailId: exp.experienceDetailId,
        //         });
        //         uniqueId++;
        //     });
        // }
        // setAllExpAndBundles(allExp);

        const allExp = [];
        let uniqueId = 0;
        if (contractExperiences && contractExperiences.length) {
            contractExperiences.forEach((exp) => {
                allExp.push({
                    ...exp,
                    experiencer: experiencerName,
                    experienceId: exp.id,
                    id: uniqueId,
                    expDetailId: exp.experienceDetailId,
                });
                uniqueId++;
            });
        }
        if (contractBundleExperiences && contractBundleExperiences.length) {
            contractBundleExperiences.forEach((bld) => {
                bld.experiences?.forEach((exp) => {
                    exp.expDetailId = exp.id;
                    exp.experienceId = exp.expId;
                    exp.id = uniqueId;
                    uniqueId++;
                });
                allExp.push({
                    ...bld,
                    name: `PAQUETE: ${bld.name}`,
                    bundleId: bld.id,
                    id: uniqueId,
                });
                uniqueId++;
            });
        }
        setAllExpAndBundles(allExp);
    }, [contractExperiences, contractBundleExperiences]);

    useEffect(() => {
        setContract({});
        getUserInfoSync().then(async (usr) => {
            setExperiencerName(usr.experiencer.name);
            await getExperiencesUpdated(usr.experiencer.id);
            await getContracts();
        });
    }, []);

    // const renderColumn = (title, children) => {
    //     return (
    //         <div style={{ paddingRight: '2rem' }}>
    //             <h2>{title}</h2>
    //             {children}
    //         </div>
    //     );
    // };

    const handleQuantityChange = (newValue) => {
        setQuantity(newValue);
    };

    // const handleSubmit = async (e) => {
    //     const data = {
    //         email: values.email,
    //         firstName: values.firstname,
    //         lastName: values.lastname,
    //         quantity: quantity,
    //         status: values.status,
    //         mobilePhone: values.mobilePhone,
    //         mobilePhoneCountryCode: values.mobilePhoneCountryCode,
    //         additional: values.additional,
    //         items: checkoutItems,
    //     };

    //     // TODO: we pass expIds because of horrible parsing - REVIEW Later
    //     const { bookingId, bookingCode, paymentId, payNow, payTotal } =
    //         await addBooking(data.experienceId, data.expDetailId, data);
    //     if (!bookingId) {
    //         toast.error(`Hubo un error al intentar crear la reserva!`, {
    //             position: 'top-right',
    //             theme: 'colored',
    //         });
    //         const auxCheckout = [];
    //         setCheckoutItems(auxCheckout);
    //         history.push(ROUTES.BOOKINGS);
    //     } else {
    //         setBookingId(bookingId);
    //         setBookingCode(bookingCode);
    //         setSuccessOpen(true);
    //     }
    // };

    // const required = (value) => {
    //     return value ? undefined : translate('form.required');
    // };

    // const validateEmail = (email) => {
    //     return String(email)
    //         .toLowerCase()
    //         .match(
    //             /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //         );
    // };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setValues({
    //         ...values,
    //         [name]: value,
    //     });
    // };

    // const renderBookingForm = () => {
    //     return (
    //         <>
    //             <Form
    //                 onSubmit={handleSubmit}
    //                 initialValues={{ ...values }}
    //                 render={({ handleSubmit, values }) => (
    //                     <form onSubmit={handleSubmit} noValidate>
    //                         {/* FORM ROWS */}
    //                         <Grid2
    //                             container
    //                             spacing={2}
    //                             direction="row"
    //                             justifyContent="space-between"
    //                             alignItems="center">
    //                             <Grid2 item xs={12} sm={12} md={6} lg={6} xl={6}>
    //                                 <Field
    //                                     style={{
    //                                         marginTop: '10px',
    //                                         marginBottom: '10px',
    //                                     }}
    //                                     required
    //                                     validate={required}
    //                                     variant="outlined"
    //                                     label={translate(
    //                                         'bookingForm.firstname'
    //                                     )}
    //                                     name="firstname"
    //                                     value={values.firstName}
    //                                     onTextFieldChange={handleInputChange}
    //                                     component={TextInput}
    //                                 />
    //                             </Grid2>
    //                             <Grid2 item xs={12} sm={12} md={6} lg={6} xl={6}>
    //                                 <Field
    //                                     style={{
    //                                         marginTop: '10px',
    //                                         marginBottom: '10px',
    //                                     }}
    //                                     required
    //                                     validate={required}
    //                                     variant="outlined"
    //                                     label={translate(
    //                                         'bookingForm.lastname'
    //                                     )}
    //                                     name="lastname"
    //                                     value={values.lastName}
    //                                     onTextFieldChange={handleInputChange}
    //                                     component={TextInput}
    //                                 />
    //                             </Grid2>
    //                         </Grid2>
    //                         <Grid2
    //                             container
    //                             spacing={2}
    //                             direction="row"
    //                             justifyContent="space-between"
    //                             alignItems="center">
    //                             <Grid2 item xs={12} sm={12} md={6} lg={6} xl={6}>
    //                                 <Field
    //                                     style={{
    //                                         marginTop: '10px',
    //                                         marginBottom: '10px',
    //                                     }}
    //                                     required
    //                                     validate={(email) => {
    //                                         if (!email) {
    //                                             return translate(
    //                                                 'form.required'
    //                                             );
    //                                         }
    //                                         if (!validateEmail(email)) {
    //                                             return translate(
    //                                                 'form.invalid.email'
    //                                             );
    //                                         }
    //                                         return undefined;
    //                                     }}
    //                                     variant="outlined"
    //                                     label="Email"
    //                                     name="email"
    //                                     value={values.email}
    //                                     onTextFieldChange={handleInputChange}
    //                                     component={TextInput}
    //                                 />
    //                             </Grid2>
    //                             <Grid2 item xs={12} sm={12} md={6} lg={6} xl={6}>
    //                                 <Field
    //                                     style={{
    //                                         marginTop: '15px',
    //                                         marginBottom: '15px',
    //                                     }}
    //                                     id="phone"
    //                                     onFieldChange={(value, country) => {
    //                                         const auxValues = {
    //                                             ...values,
    //                                             mobilePhone: value,
    //                                             mobilePhoneCountryCode: country,
    //                                         };
    //                                         setValues(auxValues);
    //                                     }}
    //                                     name="mobilePhone"
    //                                     component={PhoneNumber}
    //                                     hintText={translate('profile.phone')}
    //                                     label={
    //                                         translate('bookingForm.phone') +
    //                                         ' *'
    //                                     }
    //                                     initialCountry={'us'}
    //                                     validateField={(value, countryCode) =>
    //                                         value.length > 0 &&
    //                                         value.startsWith(countryCode) &&
    //                                         value.length > countryCode.length
    //                                     }
    //                                 />
    //                             </Grid2>
    //                         </Grid2>
    //                         <Grid2
    //                             container
    //                             spacing={2}
    //                             direction="row"
    //                             justifyContent="flex-end"
    //                             alignItems="center">
    //                             <Grid2 item xs={12} sm={12} md={6} lg={6} xl={6}>
    //                                 <Field
    //                                     style={{
    //                                         marginBottom: '2em',
    //                                         width: '100%',
    //                                     }}
    //                                     onChange={(e) => {
    //                                         const auxValues = {
    //                                             ...values,
    //                                             additional: e.target.value,
    //                                         };
    //                                         setValues(auxValues);
    //                                     }}
    //                                     id="additional"
    //                                     name="additional"
    //                                     component={'textarea'}
    //                                     label={'Additional Comments'}
    //                                     placeholder={'Extra info'}
    //                                     multiline
    //                                     rows={6}
    //                                 />
    //                             </Grid2>
    //                         </Grid2>
    //                         {/* BUTTONS */}
    //                         <Grid2
    //                             container
    //                             spacing={3}
    //                             justifyContent="flex-end"
    //                             style={{ marginTop: '25px' }}>
    //                             <Grid2 item>
    //                                 <Button
    //                                     variant="contained"
    //                                     color="default"
    //                                     onClick={(event) => {
    //                                         const auxCheckout = [];
    //                                         setCheckoutItems(auxCheckout);
    //                                         history.push(ROUTES.BOOKINGS);
    //                                     }}
    //                                     type="button">
    //                                     {translate(
    //                                         'trip.create.buttons.cancel'
    //                                     )}
    //                                 </Button>
    //                             </Grid2>
    //                             <Grid2 item>
    //                                 <Button
    //                                     variant="contained"
    //                                     color="primary"
    //                                     disabled={showErrorPrice}
    //                                     type="submit">
    //                                     RESERVAR
    //                                     {/* {translate('form.continue')} */}
    //                                 </Button>
    //                             </Grid2>
    //                         </Grid2>
    //                     </form>
    //                 )}
    //             />
    //         </>
    //     );
    // };

    const handleDatePickerChange = (date) => {
        setDateDatePicker(date);
        setTimeOfArrival(null);
    };

    const handleMonthChange = (date) => {
        setScheduleDate(formatDateToYYYYMMDD(date));
    };
    useEffect(() => {
        const getCategories = async () => {
            try {
                const categoriesData = await ExperienceResourceService.getResourceCategoryExperience(selectedExperienceItem?.experienceId, { getResources: true });
                if (!categoriesData.resourceCategories) {
                    throw new Error('No se pudieron cargar las categorías');
                }
                const categoriesCopy = categoriesData.resourceCategories.map((item) => ({
                    ...item,
                }));
                setRawDataCategories(categoriesCopy);
                setCategoriesData(categoriesData.resourceCategories);
                return categoriesData;
            } catch (err) {
                console.error(err);
                return false;
            }
        };

        if (selectedExperienceItem) {
            getCategories();
        }
    }, [selectedExperienceItem]);

    return (
        <Layout contentClassName="content">
            <Grid2 container style={{ padding: 25 }}>
                <CheckoutPageComponent
                    categoriesData={categoriesData}
                    totalContracts={contracts}
                    totalItems={allExpAndBundles}
                    contract={contract}
                    handleSelectContract={handleSelectContract}
                    handleClickAvailabilityPopper={handleClickAvailabilityModal}
                    setSelectedExperienceItem={setSelectedExperienceItem}
                    selectedExperienceItem={selectedExperienceItem}
                    onCloseAvailabilityModal={onCloseAvailabilityModal}
                    quantity={quantity}
                    selectedSlot={selectedSlot}
                    translate={translate}
                    setQuantity={setQuantity}
                    addCheckoutItem={addCheckoutItem}
                    checkoutItems={checkoutItems}
                    setCheckoutItems={setCheckoutItems}
                    scheduleDate={scheduleDate}
                    resourceSelected={resourceSelected}
                    addBooking={addBooking}
                    addContractBookingPayment={addContractBookingPayment}
                    deleteContractBookingPayment={deleteContractBookingPayment}
                    bookingCode={bookingCode}
                    bookingId={bookingId}
                    setBookingCode={setBookingCode}
                    setBookingId={setBookingId}
                    handleModalSuccess={handleModalSuccess}
                    useAuth={useAuth}
                    rawDataCategories={rawDataCategories}
                />
            </Grid2>
            <Grid2 container style={{ padding: '2rem' }}>
                <Grid2 size={{ xs: 12, sm: 4, md: 6, lg: 4, xl: 4 }} container>
                    {!selectedExperienceItem && !openAvailabilityModal ? null : (
                        <Grid2 size={12} style={{ marginBottom: '1rem' }}>
                            {/* {renderColumn( */}
                            {/* `${translate('global_bookings.pick_a_date')}`, */}
                            <AlertDialog
                                open={openAvailabilityModal}
                                handleClose={onCloseAvailabilityModal}
                                dialogTitle=" "
                                labelBack="Guardar"
                                className={classes.modalContent}
                                hideSubmit={true}
                                dialogContent={
                                    <>
                                        <ExperienceAvailabilityPageComponent
                                            showLoading={loading}
                                            showPrice={false}
                                            showTitle={false}
                                            showQuantity={true}
                                            quantity={quantity}
                                            datePicker={dateDatePicker}
                                            resources={categoriesData}
                                            slots={schedule}
                                            experience={selectedExperienceItem}
                                            setQuantity={handleQuantityChange}
                                            setDatePicker={(date) => {
                                                handleDatePickerChange(date);
                                            }}
                                            setVisibleRange={setVisibleRange}
                                            setSelectedSlot={(slot) => {
                                                setSelectedSlot(slot);
                                            }}
                                            setResourceSelected={setResourceSelected}
                                            resourceSelected={resourceSelected}

                                            // onMonthChange={(date) => {
                                            //     handleMonthChange(date);
                                            // }}
                                            // setTimeOfArrival={setTimeOfArrival}
                                            // addBookedExperienceWithQuantityPrice={(
                                            //     experience,
                                            //     price,
                                            //     quanity
                                            // ) => {
                                            //     // addCheckoutItem(
                                            //     //     experience,
                                            //     //     price,
                                            //     //     quanity
                                            //     // );
                                            // }}
                                        />
                                    </>
                                }
                            />
                        </Grid2>
                    )}
                </Grid2>
                <Grid2>
                    <AlertDialog
                        open={successOpen}
                        dialogTitle={'Booking Confirmation'}
                        hideTitle={true}
                        dialogContent={`Reserva realizada con éxito! Codigo: ${bookingCode}`}
                        handleClose={() => {
                            setSuccessOpen(false);
                            const auxCheckout = [];
                            setCheckoutItems(auxCheckout);
                            history.push(ROUTES.BOOKINGS.GLOBAL);
                        }}
                        handleSubmit={() => {
                            setSuccessOpen(false);
                            const auxCheckout = [];
                            setCheckoutItems(auxCheckout);
                            history.push(`${ROUTES.BOOKINGS.GLOBAL}/${bookingId || ''}`);
                        }}
                        labelBack="Cancel"
                        hideBack={true}
                        labelNext="Ok"
                    />
                </Grid2>
                {/* <Grid2
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={8}
                    xl={8}
                    style={{ marginBottom: '1rem' }}>
                    {checkoutItems.length ? (
                        <>
                            <Grid2
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{ marginBottom: '1rem' }}>
                                <h2>
                                    {translate(
                                        'global_bookings.booking_summary',
                                        'Resumen'
                                    )}
                                </h2>
                                <BookingSummary
                                    showErrorPrice={showErrorPrice}
                                    setShowErrorPrice={setShowErrorPrice}
                                    quantity={quantity}
                                    checkoutId={checkoutId}
                                    setCheckoutId={setCheckoutId}
                                    checkoutItems={checkoutItems}
                                    setCheckoutItems={setCheckoutItems}
                                    setSelectedExperienceItem={
                                        setSelectedExperienceItem
                                    }
                                    onChangeDate={(val) => {
                                        setSelectedExperienceItem({ ...val });
                                    }}
                                    onRemoveItem={(val) => {
                                        setSelectedExperienceItem(null);
                                        const auxCheckout =
                                            checkoutItems.filter((it) => {
                                                return it.id !== val.id;
                                            });
                                        setCheckoutItems(auxCheckout);
                                    }}
                                />
                            </Grid2> */}
                {/* <Grid2
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{ marginBottom: '1rem' }}>
                                <h2>
                                    {translate(
                                        'global_bookings.triper_information',
                                        'Triper Information'
                                    )}
                                </h2>
                                {renderBookingForm()}
                            </Grid2>
                        </>
                    ) : (
                        ''
                    )} */}
                {/* </Grid2> */}
            </Grid2>
        </Layout>
    );
};

export default BookingNew;
